





import { Component, Vue, Prop } from "vue-property-decorator";
import { GetUser } from "@/request/account";
@Component({})
export default class Name extends Vue {
  private get user() {
    return this.$store.state.user;
  }
  private created() {
    const params = {
      doctor_id: this.user.doctor_id,
      source: "pc医生",
    };
    GetUser(this, params).then((res: any) => {});
  }
}
